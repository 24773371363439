<template>
  <div>
   
    <div class="wrap d-flex justify-content-center">
       <div class="search">
          <input type="text" class="searchTerm" 
                  placeholder="Search Products" v-model="search"
                  @input="betterSearch" 
                  @keyup="onKeyUp" 
            >
       </div>
       <button type="submit" class="searchButton" @click= "betterSearch">
        <i class="fa fa-search"></i>
         </button>
      </div>
       <!--Search bar End -->
      <HomepageCategoty  class="category-design mt-2 mt-lg-0" :color="color" />
    <div class="">
   
      <div class="container product ">
        

        <div  class="mb-10">
          <!-- <div class="text-center">
            <h1>No more product</h1>
          </div> -->

        </div>
        <div v-if="this.$store.state.product.loader" class="row">
          <div
            v-for="n in 10"
            :key="n"
            class="text-center col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3"
          >
            <skeleton-box
              width="260px"
              height="200px"
              style="border-radius: 10%"
            />
          </div>
        </div>
        <div v-if="!this.$store.state.product.loader" class="">
          <div v-if="products.length < 1" class="mt-5">
            <h1 class="text-center">No product found...</h1>
          </div>
         
          <div v-for="item in categories" :key="item.categories_id" class="text-black" style="text-transform: uppercase">
            <h1 v-if="item.categories_id === categoryname" class="text-center Category-title" style=" font-family: CastorTwoW01-Regular;">
                   {{ item.name }}  
              </h1>
            
          <div v-if="item.categories_id === categoryname">
          <div v-for="cat in categori" :key="cat" style="" >
            <div v-if="cat != item.name " class="product-nam">
            <div v-if="!search">
              <div v-if="cat != item.name ">
                <hr v-if="categories.name !== cat" style="" class="d-none d-lg-flex productline"/>
                <h1 class="text-center  sideline" style=" ">{{ cat }}</h1>
                 <hr v-if="categories.name !== cat" style="" class="d-none d-lg-flex productline2" />
              </div>
              
            <ProductList class="blog-area"
              v-if="!showComponent && cat !== categoryname"
              :products="
                products.filter((product) => product.category.name === cat && cat !== item.name && product.is_published == 1 )
              "
            />
           
          </div>
            <div v-if="search">
              <ProductList
              v-if="!showComponent"
              :products="
                products.filter((product) => product.category.name === cat)
              "
            />
            </div>
          </div>
        </div>
        </div>
      </div>
          <template v-if="showComponent">
            <div v-if="allProducts.length < 1" class="col-md-12">
              <h6 class="text-center">No product found...</h6>
            </div>
            <ProductList :products="allProducts" />
          </template>
        </div>
      </div>
    </div>
   
    <notifications group="cart" position="top left" />
    <!-- snackbar -->
    <v-snackbar v-model="snackbar">
      {{ text }}
      <v-btn color="pink" text @click="snackbar = false"> Close </v-btn>
    </v-snackbar>
    <!-- snackbar -->
  </div>
</template>

<script>
import ProductList from "@/components/NewProductList";
import SkeletonBox from "../common_components/SkeletonBox.vue";
import _ from 'lodash';
import { mapGetters } from "vuex";
import HomepageCategoty from "../home/HomepageCategoty.vue";
export default {
  name: "estoreProductByCategory",
  components: { 
    ProductList,
     SkeletonBox,
      HomepageCategoty,
     },
  props: ["title"],
  data() {
    return {
      cate:[],
      category: [],
      snackbar: false,
      text: "",
      search: '',
      tempProducts: [],
      state: 0,
      articles: [],
      img: "https://s3.ap-south-1.amazonaws.com/german-butcher/",
      color: '#2F4F4F'
    };
  },
  methods: {
    
    replaceSpace(name) {
      return name.replace(/[\W_]/g, "-");
    },

    cartAdded(product) {
      this.productId = product.product_id;
      this.$store.commit("addTCart", product);
      (this.snackbar = true), (this.text = "Added to cart");
      localStorage.setItem(
        "cart",
        JSON.stringify(this.$store.getters.cart_Info)
      );
      // this.notify()
    },
    betterSearch: _.debounce(function () {
                // this.$refs.product.resetPageNumber();
                let query = '?search=' + this.search;
                this.$store.dispatch('product/ACT_FILTER_PRODUCTS', query);
            }, 1500),
            onKeyUp() {
                this.$refs.product.startLoading();
            },
            onClickPageNumber() {
                let nextPage = this.currentPage + 1;
                let query = '?search=' + this.search + '&page=' + nextPage;
                this.$store.dispatch('product/ACT_FILTER_PRODUCTS', query);
            },

    notify() {
      this.$notify({
        group: "cart",
        type: "error",
        title: "Added to Cart",
        //text: 'Hello user! This is a notification!'
      });
    },
  }, //methods ends here
  computed: {
    ...mapGetters({
      // category: "category/GET_SUBCATEGORIES",
      categories: 'category/GET_GB_CATEGORIES'
      //products: 'product/GET_PRODUCTS_BY_CATEGORY'
    }),
   
    categori() {
      let categories = this.$store.state.product.gbProducts.map(
        (product) => product.category.name
      );
      let uniqueCat = [...new Set(categories)];
      return uniqueCat;
    },
    categoryname(){
      let categories = this.$store.state.product.gbProducts.map(
        (product) => product.category.parent_id
      );
      categories = categories.filter((catname)=> catname != 0)
      let uniqueId = [...new Set(categories)];
      const myNumber = parseInt(uniqueId[0]);
      return myNumber;
    },
    products() {
      let products = this.$store.state.product.gbProducts;
      return products;
    },
    // products2() {
    //   let products2 = this.$store.state.product.categoryProducts;
    //   return products2;
    // },
    allProducts() {
      let products = this.$store.state.product.gbProducts;
      if (this.search === "") {
        return products;
      }
      return products.filter((product) =>
        product.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
    showComponent() {
      return this.search.length !== 0 ? true : false;
    },
    id: function () {
      return this.$route.query.cat_id;
    },
    breadCrumb: function () {
      return this.$route.query;
    },
    query: function () {
      let query = [];
      query.type = "gb-product";
      query.id = this.$route.query.cat_id;
      return query;
    },
  },
//   created() {
//   this.$router.push({ name: 'products-list', query: { id: 1 } })
// },
  watch: {
   
    $route() {
      //this.getProductByCategoryId(this.id)
      if (this.$route.path=='/products') {
        this.$store.dispatch("product/ACT_PRODUCTS_BY_GB_CATEGORY", this.id);
        this.$store.dispatch("category/ACT_GB_CATEGORIES_LIST", this.query);
      }
      
    },
  },
  async mounted() {
    // this.getProductByCategoryId(1)
    // this.$store.dispatch('category/ACT_CATEGORIES', this.query);
    // this.$store.dispatch('product/ACT_PRODUCTS_LIST');
    
    this.$store.dispatch("product/ACT_PRODUCTS_BY_GB_CATEGORY", this.id);
    

    this.loading = false;
  },
};
</script>

<style scoped>
@font-face {
    font-family: 'Quicksand', sans-serif;
    src: url('../../../public/fonts/Quicksand-VariableFont_wght.ttf');
} 
@font-face {
    font-family: 'CastorTwoW01-Regular';
    src: url('/fonts/Castor\ Two\ W01\ Regular.ttf');
} 
hr{
        height:4px;
        background-color: #d52b05;
        border: none;
    }
.category-design{
  margin-top: -40px; 
  z-index: 0;
  color: v-bind(color);
}
.product {
  min-height: calc(100vh - 500px);
}
.blog-area{
  margin-top: -50px;
}

.add-cart {
  border: 1px solid #dbd7d7;
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  color: #e54c2a;
  transition: 0.5s;
  margin-right: 20px;
}
.add-cart:hover {
  background-color: #e54c2a;
  color: #fff;
}

.product-price {
  border: 1px solid #dbd7d7;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #e54c2a;
  transition: 0.5s;
  margin-right: 20px;
}
/*@font-face {
    font-family: myThirdFont;
    src: url('/fonts/Ubuntu-M.ttf');
    }*/

.banner {
  background-image: url("/img/ecover.jpg");
  min-height: 360px;
  background-position: center;
  background-size: cover;
  padding-top: 200px;
}
@media only screen and (max-width: 730px) {

  .banner {
    min-height: 197px;
    padding-top: 106px;
  }
}

.row {
  flex: 1 1 auto !important;
}
.h3 {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 50px;

  text-align: center;
  letter-spacing: 0.02em;

  color: #c0272d;
}
.h2 {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 60px;

  text-align: center;
  letter-spacing: 0.02em;

  color: #231f20;
}
.productline{
  margin-top: 0px; 
  margin-right:  calc(100% - 420px); 
  color: blue;
}
.productline2{
  margin-top: -30px; 
  margin-left: calc(100% - 420px);
}
.op {
  position: relative;
  background: linear-gradient(270deg, #d29835 0%, #f9ecc0 53.12%, #d29835 100%);
  width: 100%;
  height: 16px;
  left: 0px;
  top: 70px;
}
.sideline{
    margin-top: -100px;
    font-family: CastorTwoW01-Regular;
  }
.product-nam{
  margin-top: 60px;
}
@media (min-width: 300px) and (max-width: 992px) {
  .sideline{
    margin-top: -40px !important;
    font-size: 32px; 
    color: #C0272D;
  }

  .Category-title{
    margin-top: 20px !important;
    font-size: 40px;
  }
}
@media (min-width: 992px) and (max-width: 1279px) {
  .productline{
    margin-top: 0px; 
    margin-right:  calc(100% - 300px); 
    color: blue;
  }
  .productline2{
  margin-top: -30px; 
  margin-left: calc(100% - 300px)
}
}
/* for search */
.wrap{
            display: flex;
            margin-top: 50px;
            width: 100%;
            
        }
    .search {
      z-index:1;
      width: 470px;
      position: relative;
      display: flex;
      border: 2px solid #C0272D;
      border-right: none;
      border-radius: 40px 0 0 40px;
      background: #E5E5E5;
    }

    @media (max-width:700px){
        .search {
            width:50%;
        }

    }
   

    
    .searchTerm {
      width: 100%;
      border-right: none;
      padding: 15px;
      height: 18px;
      border-radius: 5px 0 0 5px;
      outline: none;
      color: linear-gradient(312deg, #CF9333 0%, #EAC348 15%, #FBF1CA 46%, #DBA939 100%) ;
    }
    .searchTerm::placeholder{

    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    /* Black */
    color: #231F20;
    }
    .searchTerm:focus{
      color: #231F20;
      font-family: 'Quicksand';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    }

    
    .searchButton {
      position: relative;
      width: 103px;
      height: 64px;
      background:#231F20;
      align-content: center;
      color: #fff;
      cursor: pointer;
      font-size: 18px;
      border: 2px solid #C0272D;
      border-left: none;
      border-radius: 0px 40px 40px 0px;
    }

    .searchButton i {
      font-size: 40px;
      background: -webkit-linear-gradient(90deg, #CF9333 0.16%, #EAC348 15.12%, #FBF1CA 46.03%, #DBA939 99.88%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      height: 40px;
      width: 40px;
    }

    @media (max-width:760px){
      .search {
        left:5%;
      }
        .searchButton {
            width:30%;
            right:4%;
        }
        .searchButton i {
          position: absolute;
            font-size: 30px;
            justify-content: center;
            bottom: 12%;
        }

    }
    @media (max-width:700px){
        .searchButton {
            width:100px;
        }
        .searchButton i {
          position: absolute;
            font-size: 30px;
            justify-content: center;
            bottom: 10%;
        }
      }

      @media (max-width: 420px) {
        .wrap {
          width: 350px;
        }
        .searchButton i {
          position: absolute;
            font-size: 20px;
            justify-content: center;
            bottom: 0%;
            right:5%;
        }
        .search {
          left: 8%;
        }
        .searchButton {
            left: 0%;
        }
      }
      @media (max-width:380px){
        .searchTerm {
          width: 100%;
          padding: 1px;
        }
        .search {
          height: 100%;
        }
        .searchButton {
            width:82px;
            height:54px;
        }
        .searchButton i {
          position: absolute;
            font-size: 20px;
            justify-content: center;
            bottom: -10%;
            right:5%;
        }
      }

      @media (max-width:360px){
        .searchTerm {
          width: 100%;
          padding: 1px;
        }
        .search {
          height: 100%;
          width: 100px !important;
        }
        .searchButton {
            width:70px;
            height:54px;
        }
        .searchButton i {
          position: absolute;
            font-size: 16px;
            justify-content: center;
            bottom: -10%;
            right:-5%;
        }
      }
      @media (max-width:350px){
        .searchTerm {
          width: 100%;
          padding: 1px;
        }
        .search {
          height: 100%;
          width: 100px !important;
        }
        .searchButton {
            width:60px;
            height:54px;
        }
        .searchButton i {
          position: absolute;
            font-size: 10px;
            justify-content: center;
            bottom: -20%;
            right:-10%;
        }
        .wrap{
          width:fit-content;
        }
      }
      @media (max-width:340px){
        .searchTerm {
          width: 100%;
          padding: 1px;
        }
        .search {
          left: 8%;
          height: 100%;
          min-width: 230px !important;
        }
        .searchButton {
            width:60px;
            height:54px;
            left: 0%;
        }
        .searchButton i {
          position: absolute;
            font-size: 15px;
            justify-content: center;
            bottom: -16%;
            right:-7%;
        }
        
      }
</style>